import { getUrl } from "aws-amplify/storage";

export default class StorageService {
  retrieveAudio = async (audioFilename) => {
    const response = await getUrl({
      path: `public/audio/${audioFilename}`,
      options: {
        validateObjectExistence: false,
        expiresIn: 300,
        useAccelerateEndpoint: true,
      },
    });
    return response;
  };
}
