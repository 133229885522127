import React, { useState } from "react";
import setlistMetrobar from "./Setlist_Metrobar";
import setlistPearlStreet from "./Setlist_PearlStreet";
import setlistPieShop from "./Setlist_PieShop";
import setlistPocket from "./Setlist_Pocket";
import setlistPorchFest from "./Setlist_PorchFest";
import setlistSongbyrd from "./Setlist_Songbyrd";
import Song from "./Song";

const setlists = [
  setlistSongbyrd,
  setlistMetrobar,
  setlistPorchFest,
  setlistPocket,
  setlistPearlStreet,
  setlistPieShop,
];

export default function SetlistenerApp({ signOut, user }) {
  const [setlist, setSetlist] = useState(setlists[0]);

  const handleSetlistChange = (event) => {
    const selectedName = event.target.value;
    const selectedSetlist = setlists.find(
      (setlist) => setlist.name === selectedName
    );
    setSetlist(selectedSetlist);
  };

  const songsComponentList = setlist?.list?.map((song, index) => {
    return (
      <Song
        key={`song-${index}-${setlist.name}`}
        title={song.title}
        artist={song.artist}
        audioFilename={song.audioFilename}
        pdfFilename={song.pdfFilename}
      ></Song>
    );
  });

  return (
    <div className="space-y-5 mt-8">
      <div className="flex space-x-1 px-8">
        <span className="material-symbols-outlined text-blue-900">
          library_music
        </span>
        <h1 className="text-md text-blue-900">Setlistener</h1>
      </div>
      <div className="flex space-x-1 px-8">
        <select
          value={setlist?.name}
          onChange={handleSetlistChange}
          className="appearance-none bg-white border border-gray-400 hover:border-gray-500 px-4 py-2 rounded shadow leading-tight focus:outline-none focus:shadow-outline max-w-full w-full md:max-w-[36em] overflow-hidden overflow-ellipsis whitespace-nowrap"
        >
          {setlists.map((setlist, index) => {
            return (
              <option
                key={`setlist-${index}`}
                value={setlist.name}
                className="truncate"
              >
                {setlist.name}
              </option>
            );
          })}
        </select>
      </div>
      <div className="space-y-5">{songsComponentList}</div>
      <hr className="mx-8" />
      <div className="space-y-5 mx-8 pb-16">
        <h3 className="text-md text-gray-700">About</h3>
        <p className="text-sm text-gray-600 max-w-[35em]">
          The purpose of this site is to be a one-stop shop for practice
          materials for upcoming shows. Your entire set, charts and audio, all
          in one place. Unreleased demos alongside your favorite famous pop
          songs.
        </p>
      </div>
      <div className="flex items-center px-8 text-xs text-gray-400">
        <button
          className="rounded-full bg-gray-50 px-4 py-2 border border-gray-300 shadow whitespace-nowrap hover:bg-gray-200"
          onClick={signOut}
        >
          Sign Out
        </button>
        <p className="px-6">Signed in as {user.signInDetails.loginId}</p>
      </div>
      <footer className="px-8 py-4 text-xs text-gray-400 bg-gray-100 shadow-sm">
        Copyright © 2024 Spencer Michalski. All rights reserved.
      </footer>
    </div>
  );
}
