import { useEffect, useRef, useState } from "react";

const Audio = ({ src, title, type }) => {
  const [isLoading, setIsLoading] = useState(false);
  const audioRef = useRef(null);

  useEffect(() => {
    const audio = audioRef.current;

    const handleLoadStart = () => setIsLoading(true);
    const handleCanPlayThrough = () => setIsLoading(false);

    audio.addEventListener("loadstart", handleLoadStart);
    audio.addEventListener("canplaythrough", handleCanPlayThrough);

    return () => {
      audio.removeEventListener("loadstart", handleLoadStart);
      audio.removeEventListener("canplaythrough", handleCanPlayThrough);
    };
  }, []);

  return (
    <>
      {isLoading && <p className="px-8 text-gray-600">Loading audio...</p>}
      <audio
        ref={audioRef}
        src={src}
        type={type}
        controls
        className="rounded-lg w-full px-8"
        data-testid={`audio ${title}`}
      />
    </>
  );
};

export default Audio;
