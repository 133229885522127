const ORIGINAL = "Original";

const set = {
  name: "Pie Shop: Thursday, November 30, 2023",
  list: [
    {
      title: "Funk City (Extended Jam)",
      artist: ORIGINAL,
      audioFilename: "funk_city.m4a",
      pdfFilename: "funk_city.pdf",
    },
    {
      title: "CUFF IT",
      artist: "Beyonce",
      audioFilename: "cuff_it.m4a",
      pdfFilename: "cuff_it.pdf",
    },
    {
      title: "Validation",
      artist: ORIGINAL,
      audioFilename: "validation.mp3",
      pdfFilename: "validation.pdf",
    },
    {
      title: "Valerie",
      artist: "Mark Ronson (feat. Amy Winehouse)",
      audioFilename: "valerie.m4a",
      pdfFilename: "valerie.pdf",
    },
    {
      title: "Iced Coffee + The Jam",
      artist: ORIGINAL,
      audioFilename: "iced_coffee_plus_the_jam.m4a",
      pdfFilename: "iced_coffee_plus_the_jam.pdf",
    },
    {
      title: "Could You Be Loved",
      artist: "Billy & The Kids covering Bob Marley",
      audioFilename: null,
      pdfFilename: "could_you_be_loved.pdf",
    },
    {
      title: "Roll",
      artist: ORIGINAL,
      audioFilename: "roll.mp3",
      pdfFilename: "roll.pdf",
    },
    {
      title: "Groove",
      artist: ORIGINAL,
      audioFilename: "groove.mp3",
      pdfFilename: "groove.pdf",
    },
    {
      title: "And",
      artist: ORIGINAL,
      audioFilename: "and.m4a",
      pdfFilename: "and.pdf",
    },
    {
      title: "I Wish",
      artist: "Stevie Wonder",
      audioFilename: "i_wish.m4a",
      pdfFilename: "i_wish.pdf",
    },
  ],
};

export default set;
