import React, { useState, useEffect, useRef } from "react";
import SongPracticeResources from "./SongPracticeResources";

const Song = ({ title, artist, audioFilename, pdfFilename }) => {
  const ref = useRef(null);

  const [isPracticing, setIsPracticing] = useState(false);

  useEffect(() => {
    if (isPracticing) {
      ref.current?.scrollIntoView({ behavior: "smooth" });
    }
  }, [isPracticing, pdfFilename]);

  const handleToggle = () => {
    setIsPracticing(!isPracticing);
  };

  const headerAlignmentClass = isPracticing ? "items-baseline" : "items-center";

  return (
    <>
      <hr ref={ref} className="mx-8 border-gray-300" />
      <div className={`flex max-w-[40em] px-8 ${headerAlignmentClass}`}>
        <button
          onClick={handleToggle}
          className="bg-blue-200 hover:bg-blue-400 font-semibold py-2 px-4 rounded-3xl shadow-md text-md text-blue-600 border-blue-400 border-1"
          data-testid={`practice ${title}`}
        >
          {isPracticing ? "Close" : "Practice"}
        </button>
        <p className="px-6 font-bold text-xl text-gray-700">{title}</p>
        <p className="italic text-xs text-gray-500 flex-grow text-right">
          {artist}
        </p>
      </div>
      {isPracticing && (
        <SongPracticeResources
          title={title}
          audioFilename={audioFilename}
          pdfFilename={pdfFilename}
        />
      )}
    </>
  );
};

export default Song;
