const ORIGINAL = "Original";

const set = {
  name: "Songbyrd: Saturday, May 25, 2024",
  list: [
    {
      title: "I Will Survive",
      artist: "Gloria Gaynor",
      audioFilename: "i_will_survive.m4a",
      pdfFilename: "i_will_survive.pdf",
    },
    {
      title: "Funk City / Psychedelic Village (jam)",
      artist: ORIGINAL,
      audioFilename: "funk_city.m4a",
      pdfFilename: "funk_city.pdf",
    },
    {
      title: "Miracles",
      artist: ORIGINAL,
      audioFilename: "miracles.mp3",
      pdfFilename: "miracles.pdf",
    },
    {
      title: "Making Me Wait",
      artist: ORIGINAL,
      audioFilename: "making_me_wait.m4a",
      pdfFilename: "making_me_wait.pdf",
    },
    {
      title: "Iced Coffee",
      artist: ORIGINAL,
      audioFilename: "iced_coffee.mp3",
      pdfFilename: "iced_coffee.pdf",
    },
    {
      title: "Stayin' Alive",
      artist: "Bee Gees",
    },
    {
      title: "Groove",
      artist: ORIGINAL,
      audioFilename: "groove.mp3",
      pdfFilename: "groove.pdf",
    },
  ],
};

export default set;
