const ORIGINAL = "Original";

const set = {
  name: "The Pocket: Friday, February 9, 2024",
  list: [
    {
      title: "Hard to Handle",
      artist: "The Black Crowes",
      audioFilename: "hard_to_handle.m4a",
      pdfFilename: "hard_to_handle.pdf",
    },
    {
      title: "Groove",
      artist: ORIGINAL,
      audioFilename: "groove.mp3",
      pdfFilename: "groove.pdf",
    },
    {
      title: "Validation",
      artist: ORIGINAL,
      audioFilename: "validation.mp3",
      pdfFilename: "validation.pdf",
    },
    {
      title: "Roll",
      artist: ORIGINAL,
      audioFilename: "roll.mp3",
      pdfFilename: "roll.pdf",
    },
    {
      title: "Making Me Wait",
      artist: ORIGINAL,
      audioFilename: "making_me_wait.m4a",
      pdfFilename: "making_me_wait.pdf",
    },
    {
      title: "You Oughta Know",
      artist: "Alanis Morissette",
      audioFilename: "you_oughta_know.m4a",
      pdfFilename: "you_oughta_know.pdf",
    },
    {
      title: "No Contact",
      artist: ORIGINAL,
      audioFilename: "no_contact.mp3",
      pdfFilename: "no_contact.pdf",
    },
    {
      title: "Midnight City",
      artist: "M83",
      audioFilename: "midnight_city.m4a",
      pdfFilename: "midnight_city.pdf",
    },
  ],
};

export default set;
