/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_appsync_graphqlEndpoint": "https://wtol2p6bmng5bh4yszfyh6j57y.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-ud2z7fgg3razjb6yhr7fpec7zi",
    "aws_cognito_identity_pool_id": "us-east-1:1c2b6309-90b9-4660-8420-43cf50d9f078",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_qc4qJETn7",
    "aws_user_pools_web_client_id": "4qijatavov9i9t2r1v0hi8nr2g",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [],
    "aws_cognito_mfa_configuration": "OPTIONAL",
    "aws_cognito_mfa_types": [
        "TOTP"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "setlistenerc2b6458a4bb14751981be870ebd1bcb8ef17f-production",
    "aws_user_files_s3_bucket_region": "us-east-1"
};


export default awsmobile;
