import { Amplify } from "aws-amplify";
import React from "react";

import { withAuthenticator } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
import SetlistenerApp from "./SetlistenerApp.jsx";
import config from "./aws-exports.js";
Amplify.configure(config);

function App({ signOut, user }) {
  return <SetlistenerApp signOut={signOut} user={user} />;
}
export default withAuthenticator(App);
