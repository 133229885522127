const ORIGINAL = "Original";

const set = {
  name: "AdMo PorchFest: Saturday, May 4, 2024",
  list: [
    {
      title: "Respect",
      artist: "Aretha Franklin",
      audioFilename: "respect.m4a",
      pdfFilename: "respect.pdf",
    },
    {
      title: "Iced Coffee",
      artist: ORIGINAL,
      audioFilename: "iced_coffee.mp3",
      pdfFilename: "iced_coffee.pdf",
    },
    {
      title: "You Oughta Know",
      artist: "Alanis Morissette",
      audioFilename: "you_oughta_know.m4a",
      pdfFilename: "you_oughta_know.pdf",
    },
    {
      title: "No Contact",
      artist: ORIGINAL,
      audioFilename: "no_contact.mp3",
      pdfFilename: "no_contact.pdf",
    },
    {
      title: "Tennessee Whiskey",
      artist: "Chris Stapleton",
      audioFilename: "tennessee_whiskey.m4a",
      pdfFilename: "tennessee_whiskey.pdf",
    },
    {
      title: "Making Me Wait",
      artist: ORIGINAL,
      audioFilename: "making_me_wait.m4a",
      pdfFilename: "making_me_wait.pdf",
    },
    {
      title: "Rebel Rebel",
      artist: "David Bowie",
      audioFilename: "rebel_rebel.m4a",
      pdfFilename: "rebel_rebel.pdf",
    },
    {
      title: "Groove",
      artist: ORIGINAL,
      audioFilename: "groove.mp3",
      pdfFilename: "groove.pdf",
    },
    {
      title: "All My Love",
      artist: "Noah Kahan",
      audioFilename: "all_my_love.m4a",
      pdfFilename: "all_my_love.pdf",
    },
    {
      title: "Roll",
      artist: ORIGINAL,
      audioFilename: "roll.mp3",
      pdfFilename: "roll.pdf",
    },
  ],
};

export default set;
