import DocViewer, { PDFRenderer } from "@cyntler/react-doc-viewer";
import React, { useEffect } from "react";
import getEventListeners from "./DismantleDocResizing";
import "react-pdf/dist/Page/AnnotationLayer.css";
import "react-pdf/dist/Page/TextLayer.css";

const ChartViewer = ({ pdfFilename }) => {
  useEffect(() => {
    const resizeHandler = getEventListeners(window)?.find(
      (listener) => listener.type === "resize"
    )?.listener;
    if (resizeHandler) {
      window.removeEventListener("resize", resizeHandler);
    }
  }, []);

  const docs = [{ uri: require(`./pdf/${pdfFilename}`) }];

  return (
    <>
      <DocViewer
        documents={docs}
        pluginRenderers={[PDFRenderer]}
        config={{
          pdfVerticalScrollByDefault: true,
          header: { disableHeader: true },
        }}
      />
    </>
  );
};

export default ChartViewer;
