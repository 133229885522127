import React, { useRef, useState } from "react";
import ChartViewer from "./ChartViewer";
import Audio from "./Audio";
import StorageService from "./StorageService";

const SongPracticeResources = ({ title, audioFilename, pdfFilename }) => {
  const [storedAudioFileHref, setStoredAudioFileHref] = useState(null);
  const storageService = useRef(new StorageService());

  const retrieveAudio = async () => {
    const storedAudioFileHref = await storageService.current.retrieveAudio(
      audioFilename
    );
    setStoredAudioFileHref(storedAudioFileHref.url.href);
  };

  if (audioFilename && !storedAudioFileHref) {
    retrieveAudio();
  }

  const shouldRenderAudio = audioFilename && storedAudioFileHref;
  const audioSourceType = audioFilename?.endsWith(".m4a")
    ? "audio/mp4"
    : "audio/mpeg";

  return (
    <div className="space-y-2">
      {shouldRenderAudio ? (
        <Audio src={storedAudioFileHref} title={title} type={audioSourceType} />
      ) : (
        <p className="px-8">Audio coming soon.</p>
      )}
      {pdfFilename ? (
        <ChartViewer title={title} pdfFilename={pdfFilename} />
      ) : (
        <p className="px-8">Chart coming soon.</p>
      )}
    </div>
  );
};

export default SongPracticeResources;
