const ORIGINAL = "Original";

const set = {
  name: "Metrobar: Friday, May 31, 2024",
  list: [
    {
      title: "Cory Wong",
      artist: "Vulfpeck",
      audioFilename: "cory_wong.m4a",
    },
    {
      title: "And",
      artist: ORIGINAL,
      audioFilename: "and.m4a",
      pdfFilename: "and.pdf",
    },
    {
      title: "Disco Man + The Jam",
      artist: "Remi Wolf",
      audioFilename: "disco_man.m4a",
    },
    {
      title: "Miracles",
      artist: ORIGINAL,
      audioFilename: "miracles.mp3",
      pdfFilename: "miracles.pdf",
    },
    {
      title: "Breezin'",
      artist: "Masayoshi Takanaka",
      audioFilename: "breezin.m4a",
    },
    {
      title: "Waiting We Make",
      artist: ORIGINAL,
    },
    {
      title: "Superstition",
      artist: "Stevie Wonder",
      audioFilename: "superstition.m4a",
    },
  ],
};

export default set;
