const originalAddEventListener = EventTarget.prototype.addEventListener;
const originalRemoveEventListener = EventTarget.prototype.removeEventListener;

const listeners = new Map();

EventTarget.prototype.addEventListener = function (type, listener, options) {
  originalAddEventListener.call(this, type, listener, options);

  if (!listeners.has(this)) {
    listeners.set(this, []);
  }

  listeners.get(this).push({ type, listener });
};

EventTarget.prototype.removeEventListener = function (type, listener, options) {
  originalRemoveEventListener.call(this, type, listener, options);

  if (listeners.has(this)) {
    listeners.set(
      this,
      listeners
        .get(this)
        .filter((l) => l.type !== type || l.listener !== listener)
    );
  }
};

function getEventListeners(target) {
  return listeners.get(target) || [];
}

export default getEventListeners;
