const ORIGINAL = "Original";

const set = {
  name: "Pearl Street Warehouse: Saturday, January 6, 2024",
  list: [
    {
      title: "Sympathy For The Devil",
      artist: "The Rolling Stones",
      audioFilename: "sympathy.m4a",
      pdfFilename: "sympathy.pdf",
    },
    {
      title: "I Love You Long Time + The Jam",
      artist: ORIGINAL,
      audioFilename: "love_you_long_time.m4a",
      pdfFilename: "love_you_long_time.pdf",
    },
    {
      title: "I Fell In Love Tonight",
      artist: ORIGINAL,
      audioFilename: "i_fell_in_love_tonight.m4a",
      pdfFilename: "i_fell_in_love_tonight.pdf",
    },
    {
      title: "Funk City",
      artist: ORIGINAL,
      audioFilename: "funk_city.m4a",
      pdfFilename: "funk_city.pdf",
    },
    {
      title: "Iced Coffee",
      artist: ORIGINAL,
      audioFilename: "iced_coffee_plus_the_jam.m4a",
      pdfFilename: "iced_coffee.pdf",
    },
    {
      title: "Good Kisser",
      artist: "Lake Street Dive",
      audioFilename: "good_kisser.m4a",
      pdfFilename: "good_kisser.pdf",
    },
    {
      title: "Roll",
      artist: ORIGINAL,
      audioFilename: "roll.mp3",
      pdfFilename: "roll.pdf",
    },
    {
      title: "Groove",
      artist: ORIGINAL,
      audioFilename: "groove.mp3",
      pdfFilename: "groove.pdf",
    },
    {
      title: "Heaven",
      artist: "Los Lonely Boys",
      audioFilename: "heaven.m4a",
      pdfFilename: "heaven.pdf",
    },
  ],
};

export default set;
